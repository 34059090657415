
import qs from "qs";
import {
  communicationMapActions,
  communicationMapState
} from "@/store/modules/communication";
import * as selectOptions from "@/helpers/selectOptions";
import Vue from "vue";
import { get } from "lodash";
export default Vue.extend({
  name: "CommunicationRecipients",
  props: {
    communicationId: {
      type: String,
      required: true,
      default: ""
    }
  },
  data() {
    return {
      maxNumberOfPagers: 7,
      offset: 0,
      currentPage: 1,
      limit: 10,
      recipientsData: { data: [], links: { pages: [], totalRecords: 0 } }
    };
  },
  methods: {
    ...communicationMapActions(["getCommunicationRecipients"]),
    getRecipientName(scope: Record<string, any>) {
      const userData = get(scope, "row.userData", {});
      const { fullName = "", firstName = "N/A", lastName = "N/A" } = userData;
      return fullName || `${firstName} ${lastName}`;
    },
    async queryCommunicationRecipients(
      overideQuery: any = null
    ): Promise<void> {
      try {
        const query: any = overideQuery;
        if (!query.__limit) {
          query["__limit"] = this.limit;
        }
        if (!query.__offset) {
          query["__offset"] = this.offset;
        }
        const response = await this.getCommunicationRecipients({
          id: this.communicationId,
          query
        });
        if (response && response.data) {
          this.recipientsData = response;
        }
      } catch (error) {
        this.$appNotifyError("Failed to fetch communication recipients data");
        this.$bugSnagClient.notify(error);
      }
    },
    paginationPageChanged(pageNumber: number): void {
      this.currentPage = pageNumber;
      const page: any =
        this.recipientsData.links && this.recipientsData.links.pages
          ? this.recipientsData.links.pages.find(
              (page: any) => page.page === pageNumber
            )
          : null;
      if (page) {
        let queryString = page.url.split("?")[1];
        const queryObject = qs.parse(queryString);
        this.limit = queryObject.__limit;
        this.offset = queryObject.__offset;
        this.queryCommunicationRecipients(queryObject);
      }
    },
    getUserRoleLabel(role: string) {
      const roleOption = selectOptions.userRoles.find(
        (option: any) => option.value === role
      );
      if (roleOption) return roleOption.label;
      return "N/A";
    }
  },
  computed: {
    ...communicationMapState(["makingApiRequest"]),
    overallRecipientsCount(): any {
      return (
        this.recipientsData.links.totalRecords ||
        this.recipientsData.data.length
      );
    }
  },
  watch: {
    communicationId: {
      immediate: true,
      handler() {
        this.queryCommunicationRecipients({
          __limit: this.limit,
          __offset: this.offset
        });
      }
    }
  }
});
